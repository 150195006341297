jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu(mobileNavigationMenuSelector, {
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true,
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767) {
		$(".inside-row-nested").fitVids({
			ignore: "nofit"
		});
		$(".hero-text-block").fitVids({
			ignore: "nofit"
		});
		$(".cb-text").fitVids({
			ignore: "nofit"
		});
	}

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-toggle").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".nav-dd").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-dd").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Sticky Header */
	addStickyHeader();
	$(window).scroll(function() {
		addStickyHeader();
	});

	/* eslint-disable */
	/* Webflow */
	Webflow.require('ix').init([{
			"slug": "nav-dropdown",
			"name": "Nav Dropdown",
			"value": {
				"style": {},
				"triggers": [{
					"type": "hover",
					"stepsA": [{
						"opacity": 0,
						"height": "0px"
					}, {
						"opacity": 1,
						"height": "auto",
						"transition": "opacity 200 ease 0, height 200 ease 0"
					}],
					"stepsB": [{
						"height": "0px",
						"transition": "height 100ms ease 0, opacity 100ms ease 0"
					}]
				}]
			}
		},
		{
			"slug": "new-interaction",
			"name": "New Interaction",
			"value": {
				"style": {},
				"triggers": [{
					"type": "scroll",
					"stepsA": [],
					"stepsB": []
				}]
			}
		}
	]);

	Webflow.require('ix2').init({
		"events": {
			"e": {
				"id": "e",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_INTO_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-3",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e-2"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611ad5830e5c56378d87daae|dc05e03e-5e45-29eb-13be-3a7e8c47d5d2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611ad5830e5c56378d87daae|dc05e03e-5e45-29eb-13be-3a7e8c47d5d2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774359
			},
			"e-2": {
				"id": "e-2",
				"name": "",
				"animationType": "custom",
				"eventTypeId": "SCROLL_OUT_OF_VIEW",
				"action": {
					"id": "",
					"actionTypeId": "GENERAL_START_ACTION",
					"config": {
						"delay": 0,
						"easing": "",
						"duration": 0,
						"actionListId": "a-2",
						"affectedElements": {},
						"playInReverse": false,
						"autoStopEventId": "e"
					}
				},
				"mediaQueries": ["main"],
				"target": {
					"id": "611ad5830e5c56378d87daae|dc05e03e-5e45-29eb-13be-3a7e8c47d5d2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				},
				"targets": [{
					"id": "611ad5830e5c56378d87daae|dc05e03e-5e45-29eb-13be-3a7e8c47d5d2",
					"appliesTo": "ELEMENT",
					"styleBlockIds": []
				}],
				"config": {
					"loop": false,
					"playInReverse": false,
					"scrollOffsetValue": 0,
					"scrollOffsetUnit": "%",
					"delay": null,
					"direction": null,
					"effectIn": null
				},
				"createdOn": 1599771774360
			}
		},
		"actionLists": {
			"a-3": {
				"id": "a-3",
				"title": "nav scroll up",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-3-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-menu-wrap",
								"selectorGuids": ["531eacf5-deb5-a138-c570-1df0f1450e12"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-3-n-7",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-dd-list",
								"selectorGuids": ["991532f0-5ec4-28c3-de75-e6f1abccbd73"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-3-n-2",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-content-right-row",
								"selectorGuids": ["6d11e406-a26a-cb10-9683-bc8b3f9efcfb"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-3-n-3",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "SIBLINGS",
								"selector": ".navbar",
								"selectorGuids": ["25d82cc7-1074-e703-292c-a79b2eb87efd"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-3-n-4",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-logo",
								"selectorGuids": ["98edc09c-c49d-1d97-419d-052bee9f969c"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-3-n-5",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-logo",
								"selectorGuids": ["98edc09c-c49d-1d97-419d-052bee9f969c"]
							},
							"xValue": 1,
							"yValue": 1,
							"locked": true
						}
					}, {
						"id": "a-3-n-6",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-content-right-row",
								"selectorGuids": ["6d11e406-a26a-cb10-9683-bc8b3f9efcfb"]
							},
							"yValue": 0,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			},
			"a-2": {
				"id": "a-2",
				"title": "nav scroll down",
				"actionItemGroups": [{
					"actionItems": [{
						"id": "a-2-n",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-menu-wrap",
								"selectorGuids": ["531eacf5-deb5-a138-c570-1df0f1450e12"]
							},
							"yValue": 8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-2-n-8",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"useEventTarget": "SIBLINGS",
								"selector": ".navbar",
								"selectorGuids": ["25d82cc7-1074-e703-292c-a79b2eb87efd"]
							},
							"yValue": -15,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-2-n-4",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-logo",
								"selectorGuids": ["98edc09c-c49d-1d97-419d-052bee9f969c"]
							},
							"yValue": 8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-2-n-5",
						"actionTypeId": "TRANSFORM_SCALE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-logo",
								"selectorGuids": ["98edc09c-c49d-1d97-419d-052bee9f969c"]
							},
							"xValue": 0.85,
							"yValue": 0.85,
							"locked": true
						}
					}, {
						"id": "a-2-n-7",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".header-content-right-row",
								"selectorGuids": ["6d11e406-a26a-cb10-9683-bc8b3f9efcfb"]
							},
							"yValue": 8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}, {
						"id": "a-2-n-10",
						"actionTypeId": "TRANSFORM_MOVE",
						"config": {
							"delay": 0,
							"easing": "",
							"duration": 300,
							"target": {
								"selector": ".nav-dd-list",
								"selectorGuids": ["991532f0-5ec4-28c3-de75-e6f1abccbd73"]
							},
							"yValue": -8,
							"xUnit": "PX",
							"yUnit": "px",
							"zUnit": "PX"
						}
					}]
				}],
				"useFirstGroupAsInitialState": false,
				"createdOn": 1599769199640
			}
		},
		"site": {
			"mediaQueries": [{
				"key": "main",
				"min": 992,
				"max": 10000
			}, {
				"key": "medium",
				"min": 768,
				"max": 991
			}, {
				"key": "small",
				"min": 480,
				"max": 767
			}, {
				"key": "tiny",
				"min": 0,
				"max": 479
			}]
		}
	});
	/* eslint-enable */
});


function addStickyHeader() {
	if ($(window).scrollTop() > 1) {
		$('.navbar').addClass('sticky');
	}
	else {
		$('.navbar').removeClass('sticky');
	}
}
